import React from 'react'
import { useStructure, StructureProps } from '../useStructuresHook'
import { Loader } from '@plusplusminus/plusplusdash'

interface StructurePropsSelect extends StructureProps {
  onStructure: (id: string, locations?: any) => void
  value: string
  label: string
  shouldFilter?: boolean
}

export const StructureComponent: React.FC<StructurePropsSelect> = (props) => {
  const { locations, submitting } = useStructure(props)

  if (submitting) return <Loader />

  if (!locations.length) return null

  return (
    <div>
      <label htmlFor={props.structure} className="block text-xs font-medium text-gray-700">
        {props.label}
      </label>
      <div className="mt-1">
        <select
          value={props.value}
          onChange={(e) => props.onStructure(e.target.value, locations)}
          id={props.structure}
          name={props.structure}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          <option value="" />
          {[...locations]
            .sort((a, b) => {
              if (a.code < b.code) {
                return -1
              }
              if (a.code > b.code) {
                return 1
              }
              return 0
            })
            .map((location) => {
              if (props.structure === 'WARD') {
                return (
                  <option value={location.code}>
                    WARD {location.code}: {location.name}
                  </option>
                )
              }
              return <option value={location.code}>{location.name}</option>
            })}
        </select>
      </div>
    </div>
  )
}
