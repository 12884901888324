import { Box, Input } from '@plusplusminus/plusplusdash'
import { useEffect } from 'react'
import { ResendOtp } from './ResendOtp'

const fields = [
  {
    label: 'One Time Pin (OTP)',
    name: 'otp',
    type: 'text',
    placeholder: 'Enter one time pin',
    options: {
      required: true
    }
  },
  {
    label: null,
    name: 'token',
    type: 'hidden',
    options: {
      required: true
    }
  }
]

interface Props {
  onSubmit?: (data: any) => void
  form?: any
  token?: string
}

export const OtpVerification: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors, onSubmit, submitting } = props.form

  useEffect(() => {
    props.form.setValue('token', props.token)
  }, [props.token])

  return (
    <form autoComplete="no" onSubmit={handleSubmit(onSubmit)} className="mt-6">
      {fields.map((field) => {
        if (field.type === 'hidden') {
          return <input type={'hidden'} ref={register(field.options)} name={field.name} />
        }

        return (
          <Box className="mb-4">
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              width="full"
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              ref={register(field.options)}
              className="mb-2 w-full"
            />
            {errors[field.name] && <div className="text-sm text-red-300">{errors[field.name].message}</div>}
          </Box>
        )
      })}
      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <button
          disabled={submitting}
          className="bg-brandPurple text-brandYellow  py-5 px-10 text-sm rounded-[50px] transition-all duration-500 hover:rounded-lg Button font-sans group font-bold tracking-normal leading-5 inline-flex transition-all items-center text-center group-hover:shadow-lg ease-in-out duration-500 disabled:shadow-none disabled:opacity-70 disabled:cursor-not-allowed"
        >
          {submitting ? 'Loading...' : 'Submit'}
        </button>
        <ResendOtp token={props.token} />
      </div>
    </form>
  )
}
