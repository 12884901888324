import React from 'react'
import { Router } from '@reach/router'
import './App.css'
import { Login } from './pages/Login'
import { PrivateRoute } from './components/Auth/PrivateRouter'
import { AdminPrivateRoute } from './components/Auth/AdminPrivate'
import { ApplicationContainer } from './components/Application/ApplicationContainer'
import { Profile } from './pages/Profile'
import { CandidateRegistration } from './pages/Registration/CandidateRegistration'
import { CandidateConfirmation } from './pages/Registration/CandidateConfirmation'
import { ApplicationsContainer } from './pages/Applications'
import { ReportsContainer } from './pages/Reports'
import { UserContainer } from './components/User/UserContainer'
import { EditUser } from './components/User/ui/UserEdit'
import { Petitions } from './components/Petitions'
import { Logout } from './pages/Login/Logout'

const App: React.FC = () => {
  return (
    <Router>
      <CandidateRegistration path="/" />
      <Login path="/login" />
      <Logout path="/logout" />

      <CandidateRegistration path="/register" />
      <CandidateConfirmation path="/register/confirm" />

      <PrivateRoute component={ApplicationsContainer} path="/dashboard" />
      <PrivateRoute component={Profile} path="/profile" />
      <PrivateRoute component={ApplicationContainer} path="/application/:applicationId" />
      <PrivateRoute component={Petitions} path="/petitions" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications/:status" />
      <AdminPrivateRoute component={ReportsContainer} path="/reports" />
      <AdminPrivateRoute component={UserContainer} path="/users" />
      <AdminPrivateRoute component={EditUser} path="/users/:id" />
    </Router>
  )
}

export default App
