import { gql, useMutation } from '@apollo/client'
import { Box, Button } from '@plusplusminus/plusplusdash'
import React, { useState } from 'react'
import { StructureComponent } from './ui/StructureComponent'
import { Candidate } from './useStructuresHook'

type StructureComponentProps = {
  structure: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  filter: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  level: number
  resets?: Array<'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'>
}

type StructureForm = {
  COUNTRY: string
  PROVINCE: string
  MUNICIPALITY: string
  WARD: string
}

interface ContainerProps {
  applicationId: string
  structure: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  locations: Array<Candidate>
  refetch: () => void
  renderSubmit?: (form: StructureForm) => React.ReactNode
}

export const StructuresContainer: React.FC<ContainerProps> = (props) => {
  const [form, setForm] = useState({
    COUNTRY: 'ZA',
    WARD: '',
    MUNICIPALITY: '',
    PROVINCE: ''
  })

  const [addCandidate] = useMutation(ADD_APPLICATION_CANDIDATE)
  const [removeCandidate] = useMutation(REMOVE_APPLICATION_CANDIDATE)

  const components: Array<StructureComponentProps> = [
    {
      structure: 'PROVINCE',
      level: 6,
      filter: 'COUNTRY',
      resets: ['MUNICIPALITY', 'WARD']
    },
    {
      structure: 'MUNICIPALITY',
      level: 5,
      filter: 'PROVINCE',
      resets: ['WARD']
    },
    {
      structure: 'WARD',
      level: 4,
      filter: 'MUNICIPALITY',
      resets: []
    }
  ]

  const structureLevel = props.structure ? components.find((c) => c.structure === props.structure)?.level ?? 0 : 0

  return (
    <>
      <Box className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-4">
        {components
          .filter((c) => c.level >= structureLevel)
          .map((component) => {
            return (
              <Box className="sm:col-span-2 items-center">
                <StructureComponent
                  label={component.structure}
                  structure={component.structure}
                  type={component.filter}
                  code={form[component.filter]}
                  value={form[component.structure]}
                  onStructure={(id) =>
                    setForm({
                      ...form,
                      [component.structure]: id,
                      ...component.resets?.reduce((result, item) => {
                        result[item] = ''
                        return result
                      }, {} as StructureForm)
                    })
                  }
                />
              </Box>
            )
          })}
        {props.renderSubmit ? props.renderSubmit(form) : null}
      </Box>
      <Button
        size="xl"
        variant="primary"
        disabled={!form[props.structure]}
        onClick={async () => {
          await addCandidate({
            variables: {
              input: {
                locationCode: form[props.structure],
                applicationId: props.applicationId
              }
            }
          })
          props.refetch()
        }}
      >
        Add
      </Button>
      <Box className="mt-4">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {props.locations.map((location) => {
            return (
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="flex-shrink-0 h-5 w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <span className="ml-2 flex-1 w-0 truncate">
                    {location.location.name}
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{location.location.code}</p>
                  </span>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <Button
                    size="xl"
                    variant="secondary"
                    onClick={async () => {
                      await removeCandidate({
                        variables: {
                          applicationId: location.id
                        }
                      })
                      props.refetch()
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </li>
            )
          })}
        </ul>
      </Box>
    </>
  )
}

const ADD_APPLICATION_CANDIDATE = gql`
  mutation addLocationMutaton($input: CreateCandidateInput!) {
    addCandidate(input: $input) {
      id
    }
  }
`

const REMOVE_APPLICATION_CANDIDATE = gql`
  mutation removeLocation($applicationId: String!) {
    removeCandidate(applicationId: $applicationId)
  }
`
