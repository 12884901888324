import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../services/validate'
import { StructuresContainer } from './StructuresContainer'
import { useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { AddressState, useAddressSearch } from '../../../hooks/useAddressSearch'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: true
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateCellphone()
        }
      }
    },
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateEmail()
        }
      }
    },
    type: 'email'
  },
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateIdNumber()
        }
      }
    },

    type: 'text'
  },
  {
    label: 'Home Address',
    name: 'address',
    placeholder: 'Enter your home address',
    type: 'address'
  },
  {
    label: 'I would like to observe in my registered voting ward',
    name: 'voting_ward',

    placeholder: '',
    options: {},
    type: 'checkbox'
  },
  {
    label: 'I would like to observe in other wards',
    name: 'outside_ward',

    placeholder: '',
    options: {},
    type: 'checkbox'
  },
  {
    label: 'I hereby declare that the information provided above is true and complete to the best of my knowledge.',
    name: 'candidate',
    placeholder: '',
    options: {},
    type: 'checkbox'
  }
]

interface Props {
  isLoading?: boolean
  onSubmit?: (data: any, addressState?: AddressState) => void
}

export const RegistrationForm: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors } = useForm({})

  const { addressState, handlePlaceSelect } = useAddressSearch({})
  const [formError, setFormError] = useState('')

  const onRegister = async (data: any) => {
    console.log(addressState)
    if (props.onSubmit) {
      props.onSubmit(data, addressState)
    }
  }

  return (
    <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)} className="mt-6 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {form.map((field) => {
          if (field.type === 'address') {
            return (
              <Box>
                <label htmlFor={'location'} className="block text-sm font-medium text-gray-900 mb-1">
                  {field.label}
                </label>
                <Autocomplete
                  className="p-2 rounded border-2 border-gray-200 mb-2 w-full w-full"
                  apiKey="AIzaSyAbSui3xvIK1mhbFzgrDb408aqVIQN4xxs"
                  onPlaceSelected={handlePlaceSelect}
                  types={['address']}
                  componentRestrictions={{ country: 'za' }}
                  placeholder={field.placeholder}
                />
              </Box>
            )
          }
          if (['checkbox', 'radio'].includes(field.type)) {
            return (
              <Box className="flex items-center col-span-2">
                <input
                  id={field.name}
                  name={field.name}
                  type={field.type}
                  ref={register(field.options)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                  {field.label}
                </label>
              </Box>
            )
          }

          return (
            <Box className="col-span-2 md:col-span-1">
              <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
                {field.label}
              </label>
              <Input
                as="input"
                variant="standard"
                width="full"
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                ref={register(field.options)}
                className="mb-2 w-full"
              />
              {errors[field.name] && errors[field.name].type === 'required' ? (
                <div className="text-sm text-red-400">Required Field</div>
              ) : null}
              {errors[field.name] && errors[field.name].type === 'manual' ? (
                <div className="text-sm text-red-400">Invalid Field Format</div>
              ) : null}
            </Box>
          )
        })}
      </div>

      {formError ? <div className="text-sm text-red-400">{formError}</div> : null}

      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <button
          disabled={props.isLoading}
          className="bg-brandPurple text-brandYellow  py-5 px-10 text-sm rounded-[50px] transition-all duration-500 hover:rounded-lg Button font-sans group font-bold tracking-normal leading-5 inline-flex transition-all items-center text-center group-hover:shadow-lg ease-in-out duration-500 disabled:shadow-none disabled:opacity-70 disabled:cursor-not-allowed"
        >
          <span className="mx-auto flex items-center">
            <span className="flex items-center whitespace-nowrap text-center">
              {props.isLoading ? 'Submitting...' : 'Submit Registration'}
            </span>
          </span>
        </button>
      </div>
    </form>
  )
}
