import { useMutation } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useState } from 'react'
import { LayoutBox } from '../../components/LayoutBox'
import { useOtpVerify } from '../../hooks/useRegistration'
import { InputValidator } from '../../services/validate'
import { OtpVerification } from './ui/OtpVerification'
import { RegistrationForm } from './ui/RegistrationForm'
import { AddressState } from '../../hooks/useAddressSearch'
import { Alert } from '../../components/Alert'

interface RegisterFormData {
  firstName: string
  lastName: string
  cellphone: string
  email: string
  idnumber: string
  pdcandidate: boolean
  candidate: boolean
  mayor: boolean
  voting_ward: boolean
  outside_ward: boolean
  locations?: Array<{ name: string; code: string }>
  address?: AddressState
}

export const CandidateRegistration: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState('form')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ message: '', action: '' })
  const [user, setUser] = useState({ id: '', token: '' })
  const form = useOtpVerify({
    activate: true,
    onSuccess: () => {
      navigate('/register/confirm')
    },
    onError: (err: string) => {
      console.log(err)
    }
  })

  const [register] = useMutation(REGISTER_USER)

  const onRegister = (data: RegisterFormData, address?: AddressState) => {
    const { pdcandidate, candidate, mayor, voting_ward, outside_ward, locations, ...rest } = data
    setLoading(true)
    const roles: string[] = []
    setError({ message: '', action: '' })

    register({
      variables: {
        input: {
          ...rest,
          cellphone: new InputValidator(data.cellphone).formatCellphone('E.164'),
          meta: {
            templateRoles: roles,
            templateId: process.env.REACT_APP_TEMPLATE_ID || 'cceed70e-c0b3-45ef-9cb1-4074dbafbcaf',
            voting_ward,
            outside_ward,
            locationsIds: locations?.map((location) => location.code),
            address: address || null
          }
        }
      }
    })
      .then(({ data }) => {
        setStep('otp')
        setUser(data.register)
      })
      .catch((err) => {
        console
        if (err.message === 'User already exists.') {
          setError({
            message: 'You have already registered. Our team will review your application shortly.',
            action: 'login'
          })
        } else {
          setError({
            message: 'Unexpected error has occured please contact support for assistance.',
            action: ''
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'form':
        return (
          <LayoutBox
            title="Register as a Ground Work Collective observer in the upcoming National Elections."
            formTitle="Observer Registration"
            description="Observers ensure the fairness and integrity of elections by monitoring the voting, counting, and
        tabulation processes."
            showLogo={true}
          >
            {error.message ? <Alert message={error.message} action={undefined} title={''} color="yellow" /> : null}
            <RegistrationForm onSubmit={onRegister} isLoading={loading} />
          </LayoutBox>
        )

      case 'otp':
        return (
          <LayoutBox
            title="Register as a Ground Work Collective observer in the upcoming National Elections."
            formTitle="Confirm Cellphone Number"
            description="A six digit One Time Pin (OTP) has been sent via SMS to the cellphone number you provided for registration. Please enter
            this OTP to verify your cellphone number."
            showLogo={true}
          >
            <OtpVerification form={form} token={user.token} />
          </LayoutBox>
        )
    }
  }

  return <>{getWizardComponent(step)}</>
}

const REGISTER_USER = gql`
  mutation registerUser($input: CreateAuthInput!) {
    register: registerWithCellphoneVerification(input: $input) {
      id
      token: otpFingerprint
    }
  }
`
