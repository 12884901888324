import { Box } from '@plusplusminus/plusplusdash'
import logo from '../../logo.png'

interface Props {
  children: React.ReactNode
  showLogo: boolean
  title: string
  formTitle: string
  description?: string | React.ReactNode
}

export const LayoutBox: React.FC<Props> = (props) => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative overflow-hidden py-10 px-6 bg-green sm:px-10 xl:p-12">
              {props.showLogo ? (
                <Box className="w-full mb-8">
                  <img className="w-auto" src={logo} alt="Ground Work Collective" style={{ height: '60px' }} />
                </Box>
              ) : null}

              {props.title ? <h3 className="font-medium text-black">{props.title}</h3> : null}
            </div>

            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 h-650" style={{ minHeight: '600px' }}>
              <h3 className="text-xl font-medium text-gray-900 mb-2">{props.formTitle}</h3>
              {props.description ? <p className="mt-6 text-base text-black">{props.description}</p> : null}
              {props.children}
              <div className="mt-8 space-y-2">
                <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
                <dd className="text-sm text-gray-500">
                  Email: <a href="mailto:observer@groundworkcollective.org">observer@groundworkcollective.org</a>
                </dd>
                <dd className="text-sm text-gray-500">
                  Website: <a href="https://www.groundworkcollective.org">www.groundworkcollective.org</a>
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
