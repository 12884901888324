import { gql, useMutation } from '@apollo/client'
import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import React, { useState } from 'react'
import { useUserQuery } from '../../../hooks/useUserQuery'
import { uploadApplicationFile } from '../../../services/files'
import { HeaderWithActions } from '../../Header/HeaderWithActions'
import { Layout } from '../../Layout'
import { ListLeftRight } from '../../List/ListLeftRight'
import { StructuresContainer } from '../../Structures/StructuresContainer'
import { Candidate } from '../../Structures/useStructuresHook'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { WithdrawApplication } from './ApplicationWithdraw'
import { DownloadFile } from './DownloadFile'
import { ApplicationNote } from './ApplicationNote'

interface ApplicationFileMeta {
  requirementId: string
}

interface ApplicationFiles {
  key: string
  isApproved: boolean
  meta: ApplicationFileMeta
}

interface RequirementOption {
  structure?: 'COUNTRY' | 'PROVINCE' | 'MUNICIPALITY' | 'WARD'
  template?: string
}

interface Requirements {
  id: string
  name: string
  type: string
  isAdmin: boolean
  desription: string
  value?: string
  options?: RequirementOption
}
interface Template {
  id: string
  name: string

  requirements: Array<Requirements>
}

interface User {
  firstName: string
  lastName: string
  email: string
  cellphone: string
  idnumber: string
  address: any
}

interface ApplicationNote {
  comment: string
  createdAt: Date
}
interface RequirementMeta {
  value: string
  id: string
}
export interface ApplicationProps {
  id: string
  owner: User
  isArchived: boolean
  template: Template
  requirements: Array<RequirementMeta>
  files: Array<ApplicationFiles>
  notes: Array<ApplicationNote>
  candidates: Array<Candidate>
  status: 'pending' | 'review' | 'withdrawn'
  createdAt: string
  updatedAt: string
  requirementsCompleted: number
}

interface Props {
  application: ApplicationProps
  refetch: () => void
}

export const ApplicationView: React.FC<Props> = ({ application, refetch }) => {
  const { loading, data } = useUserQuery()
  const [fileLoad, setFileLoad] = useState('')

  const isAdmin = data.me.role === 'ADMIN'
  const [submit] = useMutation(SUBMIT_APPLICATION, {
    variables: {
      applicationId: application.id
    }
  })

  const [
    changeStatus,
    { loading: changeStatusLoading, data: changeStatusResponse, error: changeStatusErr }
  ] = useMutation(APPROVE_APPLICATION)

  const [addRequirement] = useMutation(ADD_REQUIREMENT, {})
  const [removeFile] = useMutation(REMOVE_REQUIREMENT, {})

  const onChangeRequirement = async (id: string, value: string) => {
    await addRequirement({
      variables: {
        input: {
          requirementId: id,
          value,
          applicationId: application.id
        }
      }
    })
    await refetch()
  }

  const onRemoveFile = async (id: string) => {
    await removeFile({
      variables: {
        requirementId: id,
        applicationId: application.id
      }
    })
    await refetch()
  }

  if (loading) return <div>Loading</div>

  // eslint-disable-next-line prefer-const
  let declaration = application.template.requirements.find((req) => {
    return req.type === 'declaration'
  })

  const checkValue = (id: string) => {
    const result = application.requirements.find((req) => {
      return req.id === id
    })

    if (!result) {
      return false
    }

    if (result) {
      return result.value === 'checked'
    }

    return false
  }

  const actions: React.ReactNode[] = []

  if (data.me.role === 'USER') {
    if (application.status === 'pending' && !application.isArchived) {
      actions.push(
        <Button type="button" variant="primary" onClick={() => submit()}>
          Submit
        </Button>
      )
    }
  } else if (data.me.role === 'ADMIN') {
    //if (application.status === 'review') {
    if (application.status !== 'withdrawn') {
      actions.push(
        <>
          <Button
            disabled={changeStatusLoading}
            type="button"
            variant="primary"
            colorScheme="green"
            onClick={() =>
              changeStatus({
                variables: {
                  applicationId: application.id,
                  status: 'approved'
                }
              })
            }
          >
            {changeStatusLoading ? 'Loading' : 'Approve'}
          </Button>
          <Button
            disabled={changeStatusLoading}
            type="button"
            variant="primary"
            colorScheme="red"
            className="ml-2"
            onClick={() =>
              changeStatus({
                variables: {
                  applicationId: application.id,
                  status: 'rejected'
                }
              })
            }
          >
            {changeStatusLoading ? 'Loading' : 'Reject'}
          </Button>
        </>
      )
    }

    //}
  }

  if (application.isArchived) {
    return (
      <Layout>
        <HeaderWithActions title={'Applications'} actions={actions} />
        <div className="bg-white  overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Application Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <ListLeftRight
                content={`${application.owner.firstName} ${application.owner.lastName}`}
                title={'Full Name'}
              />
              <ListLeftRight content={application.owner.idnumber} title={'ID Number'} />
              <ListLeftRight content={application.template.name} title={'Application'} />
              <ListLeftRight content={application.status} title={'Status'} />
              <ListLeftRight content={application.owner.email} title={'Email'} />
              <ListLeftRight content={application.owner.cellphone} title={'Cellphone'} />

              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {application.template.requirements
                      .filter((req) => {
                        return req.type === 'file'
                      })
                      .map((req) => {
                        const file = application.files.find((file) => file.meta.requirementId === req.id)

                        if (file) {
                          return (
                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                              <div className="w-0 flex-1 flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="flex-shrink-0 h-5 w-5 text-green-400"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <span className="ml-2 flex-1 w-0 truncate">
                                  {req.name}
                                  <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <DownloadFile applicationId={application.id} file={file.key} />
                              </div>
                            </li>
                          )
                        }
                      })}
                  </ul>
                }
                title={'Documents and Files'}
              />
              {declaration ? (
                <ListLeftRight
                  content={
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <span className="ml-2 flex-1 w-0 ">
                            <p className="mb-4 max-w-2xl text-sm text-gray-900">
                              I, {application.owner.firstName} {application.owner.lastName} with ID Number{' '}
                              {application.owner.idnumber} , do hereby declare that:
                            </p>
                            <div className="mt-3">
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  className="form-radio h-4 w-4"
                                  name="radio-sizes"
                                  checked={checkValue(declaration.id)}
                                />
                                <span className="ml-2">{declaration.desription}</span>
                              </label>
                            </div>
                          </span>
                        </div>
                      </li>
                    </ul>
                  }
                  title={declaration.name}
                />
              ) : null}
              {application.candidates && application.candidates.length ? (
                <ListLeftRight
                  description="You will need to secure a minimum of 500 endorsements from each ward in which you wish to stand"
                  content={
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {application.candidates
                        .filter((p) => !p.isArchived)
                        .map((candidate) => {
                          return (
                            <li className="pl-3 pr-4 py-3 text-sm">
                              <div className="flex items-center justify-between">
                                <div className="w-0 flex-1 flex items-center">
                                  <span className="ml-2 flex-1 w-0 truncate">
                                    <span className=" flex-1 w-0 truncate">
                                      {candidate.location.name}
                                      <p className="mt-1 max-w-2xl text-sm text-gray-500">{candidate.location.code}</p>
                                    </span>
                                  </span>

                                  <span className="ml-2 flex-1 w-0 truncate">
                                    <span className=" flex-1 w-0 truncate">
                                      Submission Count
                                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {candidate.petition.submissionCount}
                                      </p>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  }
                  title={'Petitions'}
                />
              ) : null}
            </dl>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <HeaderWithActions title={'Applications'} actions={actions} />
      <div className="bg-white  overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Application Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <ListLeftRight
              content={`${application.owner.firstName} ${application.owner.lastName}`}
              title={'Full Name'}
            />
            <ListLeftRight content={application.owner.idnumber} title={'ID Number'} />
            <ListLeftRight content={application.template.name} title={'Application'} />
            <ListLeftRight content={application.status} title={'Status'} />
            <ListLeftRight content={application.owner.email} title={'Email'} />
            <ListLeftRight content={application.owner.cellphone} title={'Cellphone'} />
            <ListLeftRight
              title={'Address'}
              content={
                <div>
                  <div className="mb-2">{application.owner.address.streetNumber}</div>
                  <div className="mb-2">{application.owner.address.addressLine1}</div>
                  {application.owner.address.addressLine2 && (
                    <div className="mb-2">{application.owner.address.addressLine2}</div>
                  )}
                  <div className="mb-2">{application.owner.address.city}</div>
                  <div className="mb-2">{application.owner.address.suburb}</div>
                  <div className="mb-2">{application.owner.address.state}</div>
                  <div className="mb-2">{application.owner.address.country}</div>
                  <div className="mb-2">{application.owner.address.zipCode}</div>
                  <div className="mb-2">
                    <span className="font-medium">Coordinates:</span> {application.owner.address.lat},{' '}
                    {application.owner.address.lng}
                  </div>
                </div>
              }
            />
            {application.template.requirements
              .filter((req) => {
                return req.type === 'location'
              })
              .map((req) => {
                return (
                  <ListLeftRight
                    title={req.name}
                    content={
                      <Box>
                        <Box className="mb-4 border-bottom-1">
                          <StructuresContainer
                            structure={req.options?.structure ?? 'WARD'}
                            applicationId={application.id}
                            locations={application.candidates.filter((p) => !p.isArchived)}
                            refetch={refetch}
                          />
                        </Box>
                      </Box>
                    }
                  />
                )
              })}
            <ListLeftRight
              content={
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {application.template.requirements
                    .filter((req) => {
                      return req.type === 'file'
                    })
                    .map((req) => {
                      const file = application.files.find((file) => file.meta.requirementId === req.id)

                      if (file) {
                        return (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="flex-shrink-0 h-5 w-5 text-green-400"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <span className="ml-2 flex-1 w-0 truncate">
                                {req.name}
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <DownloadFile applicationId={application.id} file={file.key} />
                              <Button
                                variant="secondary"
                                colorScheme="red"
                                className="ml-2"
                                onClick={() => onRemoveFile(req.id)}
                              >
                                Remove File
                              </Button>
                            </div>
                          </li>
                        )
                      }

                      return (
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <svg
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            <span className="ml-2 flex-1 w-0 truncate">
                              {req.name}
                              <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-2">{req.desription}</p>
                              {req.options?.template ? (
                                <a
                                  href={req.options.template}
                                  target="_download"
                                  className="mt-4 font-medium text-green hover:text-green-500"
                                >
                                  Download Template
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <label
                              htmlFor={`${req.id}-file-upload`}
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>{fileLoad === req.id ? 'Uploading...' : 'Upload file'}</span>
                              <Input
                                as="input"
                                variant="ghost"
                                width="full"
                                id={`${req.id}-file-upload`}
                                name={`${req.id}-file-upload`}
                                type="file"
                                className="sr-only"
                                accept="application/msword, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={async (e) => {
                                  setFileLoad(req.id)
                                  const { files = [] } = e.target as HTMLInputElement
                                  if (files?.length) {
                                    await uploadApplicationFile(application.id, req.id, files[0])
                                    refetch()
                                    setFileLoad('')
                                  }
                                }}
                              />
                            </label>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              }
              title={'Documents and Files'}
            />

            {declaration ? (
              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 w-0 ">
                          <p className="mb-4 max-w-2xl text-sm text-gray-900">
                            I, {application.owner.firstName} {application.owner.lastName} with ID Number{' '}
                            {application.owner.idnumber} , do hereby declare that:
                          </p>

                          <div className="mt-3">
                            <label className="inline-flex items-center">
                              <input
                                type="checkbox"
                                className="form-radio h-4 w-4"
                                name="radio-sizes"
                                onChange={async (e) => {
                                  if (declaration?.id) {
                                    await onChangeRequirement(declaration?.id, e.target.checked ? 'checked' : '')
                                  }
                                }}
                                checked={checkValue(declaration.id)}
                              />
                              <span className="ml-2">{declaration.desription}</span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </li>
                  </ul>
                }
                title={declaration.name}
              />
            ) : null}

            {isAdmin ? (
              <ListLeftRight
                content={
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {application.template.requirements
                      .filter((req) => {
                        return req.isAdmin
                      })
                      .map((req) => {
                        const meta = application.requirements.find((requirement) => requirement.id == req.id)

                        return (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <span className="ml-2 flex-1 w-0 truncate">
                                {req.name}
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">{req.desription}</p>
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <div className="mt-1">
                                <label className="inline-flex items-center">
                                  <input
                                    type="checkbox"
                                    className="form-radio h-4 w-4"
                                    name="radio-sizes"
                                    onChange={async (e) => {
                                      await onChangeRequirement(req.id, e.target.checked ? 'checked' : '')
                                    }}
                                    checked={meta ? meta.value === 'checked' : false}
                                  />
                                </label>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                }
                title={'Admin Actions'}
              />
            ) : null}

            <WithdrawApplication
              applicationId={application.id}
              changeStatus={changeStatus}
              changeStatusLoading={changeStatusLoading}
              status={application.status}
              response={changeStatusResponse}
              error={changeStatusErr}
            />
          </dl>
        </div>
        <section aria-labelledby="notes-title">
          <div className="bg-white border-t border-gray-200 sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                  Notes
                </h2>
              </div>

              <div className="px-4 py-6 sm:px-6">
                <ul className="space-y-8">
                  {application.notes.map((note) => {
                    return (
                      <li>
                        <div className="flex space-x-3">
                          <div>
                            <div className="text-sm">
                              <span className="font-medium text-gray-900">Note</span>
                            </div>
                            <div className="mt-1 text-sm text-gray-700">
                              <p>{note.comment}</p>
                            </div>
                            <div className="mt-2 text-sm space-x-2">
                              <span className="text-gray-500 font-medium">{note.createdAt}</span>
                              <span className="text-gray-500 font-medium">&middot;</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                  {!application.notes.length ? (
                    <li>
                      <div className="flex space-x-3">
                        <div>
                          <div className="text-sm">
                            <span className="font-medium text-gray-900">No notes added.</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <ApplicationNote applicationId={application.id} refetch={refetch} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

const SUBMIT_APPLICATION = gql`
  mutation submitApplication($applicationId: String!) {
    submitApplication(applicationId: $applicationId) {
      id
      status
    }
  }
`

const APPROVE_APPLICATION = gql`
  mutation changeStatus($applicationId: String!, $status: String!) {
    changeStatus(applicationId: $applicationId, status: $status) {
      id
      status
    }
  }
`

const ADD_REQUIREMENT = gql`
  mutation submitApplication($input: CreateApplicationRequirementInput!) {
    addApplicationRequirement(input: $input)
  }
`

const REMOVE_REQUIREMENT = gql`
  mutation removeFile($applicationId: String!, $requirementId: String!) {
    removeFile(applicationId: $applicationId, requirementId: $requirementId)
  }
`
