import { Box } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Alert } from '../../components/Alert'
import { LayoutBox } from '../../components/LayoutBox'

export const CandidateConfirmation: React.FC<RouteComponentProps> = () => {
  return (
    <LayoutBox
      title="Register as a Ground Work Collective observer in the upcoming National Elections."
      formTitle="Registration Confirmation"
      description="Thank you for submitting your application to become a election observer."
      showLogo={true}
    >
      <Box className="mt-3 prose space-y-4">
        <p>
          Our team will review your application shortly. Please expect to hear back from us with any additional
          requirements, training information, and your assignment details. We aim to ensure all observers are
          well-prepared and informed of their roles and responsibilities.
        </p>
        <p>
          Should you have any questions or need further information in the meantime, please do not hesitate to contact
          us.
        </p>
        <p>
          <strong>We look forward to working with you.</strong>
        </p>
      </Box>
    </LayoutBox>
  )
}
